<template>
  <section class="tg-authorization" data-t="tg-authorization">
    <MFormHeader class="tg-header">
      <span class="title">{{ t('telegram.registration') }}</span>
    </MFormHeader>
    <div class="content">
      <p class="subtitle">{{ t('telegram.subtitle') }}</p>
      <UserAgreement
        v-model="isAgreementChecked"
        :accent-color="'#2D9FFF'"
        class="agreement"
        is-mobile
      />
      <StButton
        size="l"
        type="custom-color"
        custom-color="#2d9fff"
        icon="telegram-solid"
        is-left-icon
        :disabled="!isAgreementChecked"
        :loading="isTgProcessing"
        @click="handleTelegramSignIn"
      >
        {{ t('telegram.register') }}
      </StButton>
      <StButton
        class="have-account"
        type="text-only"
        replace
        :to="{ query: { modal: 'login' } }"
      >
        {{ t('telegram.haveAccount') }}
      </StButton>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useUserStore } from '@st/user/stores/useUserStore'
import { useTelegram } from '@st/telegram/composables/useTelegram'
import MFormHeader from '../parts/MFormHeader.vue'
import UserAgreement from '../RegistrationForm/parts/UserAgreement.vue'

const { t, locale } = useI18n()
const router = useRouter()
const isAgreementChecked = ref(true)

const { open } = useToast()
const { auth } = useTelegram()
const { tryToAuthenticate } = useUserStore()
const isTgProcessing = ref(false)

const providersSignUpParams = computed(() => ({
  language: locale.value,
}))

async function handleTelegramSignIn() {
  try {
    isTgProcessing.value = true
    await auth(providersSignUpParams.value as any)
    await tryToAuthenticate()

    router.replace({ query: {} })
  } catch (e) {
    open({
      label: t('authorization.errorMessages.somethingWentWrong'),
      type: 'negative',
    })
    console.error('telegram auth error:', e)
  } finally {
    isTgProcessing.value = false
  }
}
</script>
<style scoped>
.tg-authorization {
  display: flex;
  flex-direction: column;

  height: 100%;
  padding-top: var(--spacing-200);

  background: linear-gradient(
      158deg,
      rgb(39 151 255 / 36%) 11.37%,
      rgb(193 229 255 / 0%) 56.65%
    ),
    linear-gradient(
      200deg,
      rgb(48 131 255 / 36%) 11.14%,
      rgb(193 229 255 / 0%) 55.56%
    ),
    var(--background-base, #111118);
  border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;

  .tg-header {
    padding: var(--spacing-050);
  }

  .title {
    width: 100%;
    padding: var(--spacing-075) 0 var(--spacing-075) var(--spacing-125);
    font: var(--mobile-title-1-semibold);
    text-align: start;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;
    padding: 0 var(--spacing-200);

    &:deep(.st-button) {
      width: 100%;
    }
  }

  .subtitle {
    align-self: flex-start;

    max-width: 328px;
    margin: 0;
    padding-bottom: var(--spacing-200);

    font: var(--mobile-text-content-regular);

    border-bottom: var(--border-width-light) solid var(--border-primary);
  }

  .agreement {
    margin: var(--spacing-250) 0 var(--spacing-300) 0;
  }

  .promo {
    width: 100%;

    &:deep(.st-button) {
      color: var(--text-tertiary);
    }
  }

  .have-account {
    margin: var(--spacing-250) 0 var(--spacing-400) 0;
    color: var(--text-tertiary);
  }
}
</style>
